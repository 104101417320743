import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  _active: {
    bg: `white`,
    bgClip: `text`,
    color: `transparent`,
    textDecoration: `none`,
  },
  _hover: {
    textDecoration: `none`,
  },
};

const variants: SystemStyleObject = {
  default: {},
  nav: {
    color: `White`,
    fontFamily: `body`,
    fontSize: [`mobile.h3`, null, `text.3`],
    fontWeight: `500`,
    textAlign: `center`,
    transition: `0s`,
    _hover: {
      borderBottom: `2px solid #FFEC00`,
    },
    _active: {
      color: `transparent`,
      textDecoration: `none`,
    },
  },
  mobilenav: {
    color: `black`,
    fontFamily: `body`,
    fontSize: [`mobile.h3`, null, `text.3`],
    fontWeight: `500`,
    textAlign: `center`,
    transition: `0s`,
    _hover: {
      borderBottom: `2px solid #FFEC00`,
    },
    _active: {
      color: `transparent`,
      textDecoration: `none`,
    },
  },
  common: {
    color: `black`,
    transition: `0s`,
    _hover: {
      color: `transparent`,
      textDecoration: `none`,
    },
  },
};

const sizes: SystemStyleObject = {};

const defaultProps: SystemStyleObject = {
  variant: `default`,
};

export const Link = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
