import { css } from '@emotion/react';

import * as font from '../fonts/index';

export const fonts = css`
  @font-face {
    font-family: 'Avenir 55';
    font-weight: 500;
    src: url(${font.AvenirRoman}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Heavy';
    font-weight: 600;
    src: url(${font.AvenirHeavy}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Next Condensed';
    font-weight: 700;
    src: url(${font.AvenirBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'CCSignLanguage';
    font-weight: 700;
    src: url(${font.CCSignLanguage}) format('truetype');
    font-display: swap;
  }
`;
