import { colors, typography } from '@/theme/foundations';

import {
  Button,
  Heading,
  Input,
  Link,
  Text,
  TextArea,
} from '@/theme/components';

export const theme = {
  styles: {
    global: {
      ul: {
        listStyle: `none`,
      },
    },
  },

  colors,
  ...typography,
  components: {
    Button,
    Heading,
    Link,
    Input,
    Text,
    TextArea,
  },
};
