import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {};

const sizes: SystemStyleObject = {
  md: {
    field: {
      pl: 3,
      borderRadius: 0,
    },
  },
};

const variants: SystemStyleObject = {};

const defaultProps: SystemStyleObject = {};

export const TextArea = {
  parts: [`addon`, `field`, `element`],
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
